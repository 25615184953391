import { useCallback, useEffect, useRef, useState } from 'react';

interface CountdownOption {
  countStart: number;
  intervalMs?: number;
  isIncrement?: boolean;
  countStop?: number;
}

interface CountdownControllers {
  startCountdown(): void;
  stopCountdown(): void;
  resetCountdown(): void;
}

export const useCountdown = ({
  countStart,
  intervalMs = 1000,
  isIncrement = false,
  countStop = 0,
}: CountdownOption): [number, CountdownControllers] => {
  const [count, setCount] = useState(countStart || 0);
  const [isCountdownRunning, setCountdownRunning] = useState(false);

  const increment = useCallback(() => setCount(prev => prev + 1), []);
  const decrement = useCallback(() => setCount(prev => prev - 1), []);
  const resetCounter = useCallback(() => setCount(countStart || 0), [countStart]);
  const startCountdown = useCallback(() => setCountdownRunning(true), []);
  const stopCountdown = useCallback(() => setCountdownRunning(false), []);

  const resetCountdown = () => {
    stopCountdown();
    resetCounter();
  };

  const countdownCallback = useCallback(() => {
    if (count === countStop) {
      stopCountdown();
      return;
    }

    if (isIncrement) {
      increment();
    } else {
      decrement();
    }
  }, [count, countStop, decrement, increment, isIncrement, stopCountdown]);

  const savedCallback = useRef(countdownCallback);
  const delay = isCountdownRunning ? intervalMs : null;

  useEffect(() => {
    savedCallback.current = countdownCallback;
  }, [countdownCallback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);

  return [
    count,
    {
      startCountdown,
      stopCountdown,
      resetCountdown,
    } as CountdownControllers,
  ];
};
