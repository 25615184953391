import { useState, useEffect, useContext, useCallback } from 'react';
import ToastContext from 'contexts/ToastContext';

export const getDomainFromEmail = (email: string) => {
  let emailDomain = null;
  const pos = email.search('@'); // get position of domain
  if (pos > 0) {
    emailDomain = email.slice(pos + 1); // use the slice method to get domain name, "+1" mean domain does not include "@"
  }
  return emailDomain;
};

export const getCompanyFromEmail = (email: string) => {
  let name = null;
  const pos = email.search('@'); // get position of domain
  if (pos > 0) {
    name = email.slice(pos + 1); // use the slice method to get domain name, "+1" mean domain does not include "@"
  }
  if (name) {
    const extension = name.search(/\./);
    name = name.substring(0, extension);
    name = name.charAt(0).toUpperCase() + name.slice(1);
  }
  return name;
};

export const useEmailDomainValidator = (email: string) => {
  const [isValid, setValid] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const domain = getDomainFromEmail(email);
        if (domain) {
          const disallowedDomains = await import('lib/disallowedEmailDomains.json').then(res => res.default);
          setValid(!disallowedDomains.includes(domain));
        } else {
          setValid(true);
        }
      } catch (e) {
        // Let's assume the email domain is good if something went wrong
        setValid(true);
      }
    })();
  }, [email]);

  return isValid;
};

export const useInvalidEmailToast = (email: string, isValid: boolean) => {
  const { setToast } = useContext(ToastContext);
  const checkMail = email.length > 5 && !isValid;

  const triggerToast = useCallback(() => {
    setToast({
      type: 'warning',
      message: 'Use your work email',
    });
  }, [setToast]);

  useEffect(() => {
    if (checkMail) {
      triggerToast();
    }
  }, [checkMail]);

  return triggerToast;
};
