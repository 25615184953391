import Link from 'next/link';
import { Button, Checkbox, Input, useForm } from '@layerise/design-core';
import { AuthWrapper, Form, FormContainer } from 'components/auth/AuthWrapper';

type FormInputs = {
  email: string;
  password: string;
  remember: boolean;
};

type Props = {
  form: {
    inputs: FormInputs;
    handleChange: ReturnType<typeof useForm>['handleChange'];
  };
  onSubmit(): void;
  loading?: boolean;
};

export function LoginView({ form, onSubmit, loading }: Props) {
  return (
    <AuthWrapper title="Login" linkHref="/auth/signup" linkTitle="Sign up" linkDescription="Don't have an account?">
      <Form
        method="post"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}>
        <fieldset disabled={loading} aria-busy={loading}>
          <FormContainer>
            <Input
              label="Email"
              type="email"
              name="email"
              placeholder="email"
              value={form.inputs.email}
              onChange={form.handleChange}
              autoComplete="email"
              required
            />
            <Input
              label="Password"
              type="password"
              name="password"
              placeholder="password"
              value={form.inputs.password}
              onChange={form.handleChange}
              autoComplete="password"
              secondaryAction={<Link href="/auth/forgotpassword">Forgot password?</Link>}
              required
            />
            <Checkbox
              label="Remember me for 30 days"
              name="remember"
              checked={form.inputs.remember}
              onChange={form.handleChange}
            />
            <Button variant="primary" size="lg" wide type="submit" disabled={loading} loading={loading}>
              Login
            </Button>
          </FormContainer>
        </fieldset>
      </Form>
    </AuthWrapper>
  );
}
