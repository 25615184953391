import { AuthCodeInput, useForm } from '@layerise/design-core';
import { AuthWrapper, ErrorMessage, Form, FormContainer } from 'components/auth/AuthWrapper';
import styled from 'styled-components';
import { BACKUP_CODE_LENGTH } from './constants';

const CodeInputContainer = styled.div`
  padding-top: 18px;
  fieldset > div:nth-child(${Math.round(BACKUP_CODE_LENGTH / 2)}) {
    margin-right: 36px;
  }
`;

type FormInputs = {
  code: string;
};

type Props = {
  form: {
    inputs: FormInputs;
    handleChange: ReturnType<typeof useForm>['handleChange'];
    setInputs(obj: Partial<FormInputs>): void;
  };
  loading?: boolean;
  onBack(): void;
  onSubmit(code: string): void;
  errorMessage?: string;
};

export function TwoFactorBackupInputView({ form, onBack, onSubmit, loading, errorMessage }: Props) {
  return (
    <AuthWrapper
      textCenter
      onBack={onBack}
      title="Enter backup verification code"
      linkTitle="Contact support"
      linkHref="mailto:cs@layerise.com"
      description={
        <>
          To continue, please enter one of the unused{' '}
          <span style={{ whiteSpace: 'nowrap' }}>{BACKUP_CODE_LENGTH}-digit</span> backup verification codes.
        </>
      }>
      <Form
        method="post"
        onSubmit={e => {
          e.preventDefault();
        }}>
        <fieldset disabled={loading} aria-busy={loading}>
          <FormContainer>
            <CodeInputContainer>
              <AuthCodeInput
                alphanumerical
                length={10}
                onChange={code => {
                  form.setInputs({ code: code.toUpperCase() });
                  if (code.length === BACKUP_CODE_LENGTH) {
                    onSubmit(code);
                  }
                }}
                value={form.inputs.code}
                disabled={loading}
              />
            </CodeInputContainer>
            <ErrorMessage>{errorMessage ? errorMessage : '‎'}</ErrorMessage>
          </FormContainer>
        </fieldset>
      </Form>
    </AuthWrapper>
  );
}
