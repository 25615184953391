import { useCallback } from 'react';
import styled from 'styled-components';
import { flags } from '@layerise/design-flag';
import { Button, useForm, PhoneInput } from '@layerise/design-core';
import { AuthWrapper, ErrorMessage, Form, FormContainer } from 'components/auth/AuthWrapper';
import { useCountries } from 'hooks/data/useCountries';
import { SMS_CODE_LENGTH } from './constants';
import { usePersona } from 'hooks/usePersona';

const PhoneInputContainer = styled.div`
  margin: 16px 0;
`;

type FormInputs = {
  telCode: string;
  tel: string;
};

type Props = {
  form: {
    inputs: FormInputs;
    handleChange: ReturnType<typeof useForm>['handleChange'];
    setInputs(obj: Partial<FormInputs>): void;
  };
  onSubmit(): void;
  onBack(): void;
  loading?: boolean;
  errorMessage?: string;
};

export function TwoFactorPhoneView({ form, loading, errorMessage, onSubmit, onBack }: Props) {
  const countries = useCountries();
  const personaQuery = usePersona();
  const country = personaQuery.data?.country;

  const { setInputs } = form;

  const handlePhoneChange = useCallback(
    (data: { code: string; phone: string }) => {
      if (personaQuery.loading) return;
      setInputs({
        telCode: data.code,
        tel: data.phone,
      });
    },
    [setInputs, personaQuery.loading]
  );

  return (
    <AuthWrapper
      textCenter
      onBack={onBack}
      title="Two-Factor authentication"
      linkTitle="Contact support"
      linkHref="mailto:cs@layerise.com"
      description={`Enter your mobile phone number. We will send you a ${SMS_CODE_LENGTH}-digit security code to verify your phone`}>
      <Form
        method="post"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}>
        <fieldset disabled={loading} aria-busy={loading}>
          <FormContainer>
            <PhoneInputContainer>
              <PhoneInput
                flags={flags}
                countries={countries}
                defaultCountryCode={country?.toString()}
                disabled={personaQuery.loading}
                onChange={handlePhoneChange}
                placeholder="Phone number"
                value={{ code: form.inputs.telCode, phone: form.inputs.tel }}
              />
            </PhoneInputContainer>
            <Button
              variant="primary"
              size="lg"
              wide
              disabled={loading || !form.inputs.tel}
              loading={loading}
              type="submit">
              Send code
            </Button>
            <ErrorMessage>{errorMessage ? errorMessage : '‎'}</ErrorMessage>
          </FormContainer>
        </fieldset>
      </Form>
    </AuthWrapper>
  );
}
