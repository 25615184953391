import { Button, Input, PasswordHint, useForm } from '@layerise/design-core';
import { useEmailDomainValidator, useInvalidEmailToast } from 'lib/emailProviders';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthWrapper } from './AuthWrapper';

const Form = styled.form`
  width: 100%;
  fieldset {
    border: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type FormInputs = {
  email: string;
  password: string;
  remember: boolean;
};

type Props = {
  form: {
    inputs: FormInputs;
    handleChange: ReturnType<typeof useForm>['handleChange'];
  };
  onSubmit(): void;
  loading?: boolean;
};

export function SignupView({ form, onSubmit, loading }: Props) {
  const isEmailDomainValid = useEmailDomainValidator(form.inputs.email);

  const triggerEmailErrorToast = useInvalidEmailToast(form.inputs.email, isEmailDomainValid);
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);

  const isFormValid = form.inputs.email && form.inputs.password && isEmailDomainValid;

  const handleSubmit: React.FormEventHandler = async e => {
    e.preventDefault();

    if (!isFormValid) {
      triggerEmailErrorToast();
      return;
    }

    onSubmit();
  };

  return (
    <AuthWrapper
      terms
      textCenter
      title="Create account"
      linkDescription="Already have an account?"
      linkTitle="Go to Login"
      linkHref="/auth"
      description="You're just 1 step from knowing your customers">
      <Form method="post" onSubmit={handleSubmit}>
        <fieldset disabled={loading} aria-busy={loading}>
          <FormContainer>
            <Input
              label="Email"
              type="email"
              name="email"
              placeholder="email"
              value={form.inputs.email}
              onChange={form.handleChange}
              autoComplete="email"
            />
            <PasswordHint password={form.inputs.password} isFocused={isPasswordInputFocused}>
              <Input
                label="Password"
                type="password"
                name="password"
                placeholder="password"
                value={form.inputs.password}
                onChange={form.handleChange}
                onFocus={() => setIsPasswordInputFocused(true)}
                onBlur={() => setIsPasswordInputFocused(false)}
                autoComplete="new-password"
              />
            </PasswordHint>

            <Button variant="primary" size="lg" wide type="submit" loading={loading} disabled={loading || !isFormValid}>
              {loading ? 'Creating account' : 'Signup'}
            </Button>
          </FormContainer>
        </fieldset>
      </Form>
    </AuthWrapper>
  );
}
