import { SVGProps } from 'react';

export function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="138"
      height="33"
      viewBox="0 0 138 33"
      aria-label="Layerise logo"
      {...props}>
      <path
        fill="#0A1223"
        d="m65.72 9.51 3.78 6.76 3.74-6.76h4.88L66.65 30.48h-4.8l4.8-8.89.5-.84-6.37-11.24Zm-14.5-.22c2 0 3.37.8 4.12 1.8l.07.1.24-1.68h3.73V24h-3.74l-.23-1.6c-.73 1.02-2.12 1.87-4.19 1.87-3.82 0-6.4-3.15-6.4-7.47s2.58-7.5 6.4-7.5Zm65.76-.25c1.88 0 3.68.55 4.78 1.46l.11.1-1.53 2.95a5.32 5.32 0 0 0-3.36-1.22c-1.2 0-1.84.48-1.84 1.14 0 .66.42.96 1.94 1.36l.14.03.63.16c2.86.75 4.24 2.1 4.24 4.22 0 2.87-2.33 4.77-6.14 4.77a8.62 8.62 0 0 1-5.28-1.63l-.12-.1 1.56-2.98a5.87 5.87 0 0 0 3.86 1.44c1.26 0 2-.54 2-1.22 0-.68-.39-1.07-1.81-1.47l-.28-.07-.47-.12-.34-.1c-2.73-.76-4.02-1.89-4.02-4 0-2.88 2.2-4.72 5.93-4.72Zm13.91 0c4.5 0 6.7 3.27 6.76 7.15v1.49h-9.82c.09 1.84 1.23 2.9 3.32 2.9a3.9 3.9 0 0 0 3.1-1.4l.08-.08 2.89 2.33c-1.52 1.73-3.44 2.58-6.16 2.58-4.54 0-7.46-3.12-7.46-7.44s2.97-7.54 7.3-7.54Zm-45.61 0c4.5 0 6.7 3.26 6.75 7.15v1.48h-9.81c.08 1.85 1.23 2.9 3.32 2.9a3.9 3.9 0 0 0 3.1-1.39l.07-.09 2.9 2.33c-1.52 1.74-3.44 2.59-6.16 2.59-4.55 0-7.47-3.13-7.47-7.45s2.98-7.53 7.3-7.53ZM34.54 4.01V20h8.78v3.99H30.27V4.02Zm67.53 5v4.26h-.34a4.2 4.2 0 0 0-3.48 1.83l-.08.1V24h-4.13V9.5h3.68l.25 1.78A4.68 4.68 0 0 1 101.9 9Zm6.04.5v14.47h-4.21V9.5Zm-55.87 3.4c-1.9 0-3.23 1.5-3.23 3.87 0 2.36 1.34 3.83 3.23 3.83 1.4 0 2.26-.71 2.88-1.69l.07-.1v-4.09c-.63-1.02-1.5-1.82-2.95-1.82Zm78.51-.73c-1.68 0-2.78 1.14-2.91 2.73v.11h5.66c-.06-1.62-1.07-2.84-2.75-2.84Zm-45.61 0c-1.68 0-2.78 1.14-2.91 2.73l-.01.1h5.66c-.05-1.61-1.06-2.83-2.74-2.83ZM106 2.52a2.5 2.5 0 1 1-2.5 2.5 2.5 2.5 0 0 1 2.5-2.5ZM20.01.07 12.23 13.7h11.7l-4.6 8.13.24-.44H0L12.13.07Zm-6.96 32.85H5.18l5.97-10.53-.97 1.74 8.73-1.55Z"
      />
    </svg>
  );
}
