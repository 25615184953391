import useSWR from 'swr';
import { fetcher } from 'lib/swr';
import type { GeolocationData } from 'pages/api/geolocation';

export const usePersona = () => {
  const { data, isLoading, error } = useSWR<GeolocationData>('/api/geolocation', fetcher);

  return {
    data,
    loading: isLoading,
    error: error,
  };
};
