import { useState } from 'react';
import styled from 'styled-components';
import { Button, Input, PasswordHint, useForm } from '@layerise/design-core';
import { AuthWrapper, Form } from 'components/auth/AuthWrapper';
import { useQuery } from '@apollo/client';
import { InvitedUserDocument } from 'types/typed-document-nodes';

const ButtonSpace = styled.div`
  margin-bottom: 24px;
`;

type FormInputs = {
  password: string;
};

type Props = {
  inviteToken: string;
  form: {
    inputs: FormInputs;
    handleChange: ReturnType<typeof useForm>['handleChange'];
  };
  onSubmit(): void;
  loading?: boolean;
};

export function InvitationView({ form, inviteToken, onSubmit, loading }: Props) {
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const invitedMemberQuery = useQuery(InvitedUserDocument, {
    skip: !inviteToken,
    variables: { inviteToken },
  });

  if (invitedMemberQuery.loading) {
    return null;
  }

  if (invitedMemberQuery.error || !invitedMemberQuery.data) {
    return (
      <AuthWrapper
        textCenter
        title="The invitation is not valid"
        description="The invitation is either expired, invalid or already used. Request a new one from the organisation owner."
      />
    );
  }

  const { invitedMember } = invitedMemberQuery.data;

  return (
    <AuthWrapper
      textCenter
      title={`You're invited to ${invitedMember?.invitedToWorkspace?.name || invitedMember?.organisation?.name}`}
      description={` Hello ${invitedMember?.email}, welcome onboard. To activate your invitation please type your preferred password below.`}
      terms>
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}>
        <fieldset disabled={loading} aria-busy={loading}>
          <ButtonSpace>
            <PasswordHint password={form.inputs.password} isFocused={isPasswordInputFocused}>
              <Input
                label="Password"
                required
                type="password"
                id="password"
                name="password"
                placeholder="Your password"
                value={form.inputs.password}
                onChange={form.handleChange}
                onFocus={() => setIsPasswordInputFocused(true)}
                onBlur={() => setIsPasswordInputFocused(false)}
                autoComplete="new-password"
                disabled={loading}
              />
            </PasswordHint>
          </ButtonSpace>
        </fieldset>

        <Button disabled={loading} loading={loading} variant="primary" size="lg" wide type="submit">
          Create account
        </Button>
      </Form>
    </AuthWrapper>
  );
}
