import { AuthCodeInput, useForm } from '@layerise/design-core';
import { AuthWrapper, Form, FormContainer, ErrorMessage } from 'components/auth/AuthWrapper';
import { useCountdown } from 'hooks/useCountdown';
import Link from 'next/link';
import { useEffect } from 'react';
import styled from 'styled-components';
import { SMS_CODE_LENGTH } from './constants';

type FormInputs = {
  code: string;
};

type Props = {
  form: {
    inputs: FormInputs;
    handleChange: ReturnType<typeof useForm>['handleChange'];
    setInputs(obj: Partial<FormInputs>): void;
  };
  phoneLastDigits?: string | null;
  onResend(): void;
  onSubmit(code: string): void;
  onBack(): void;
  onBackupCodesSelected(): void;
  loading?: boolean;
  errorMessage?: string;
};

const CodeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
`;

const ResendAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BackupCodesActionContainer = styled.div`
  color: #0b1223bd;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 1.2;
`;

export function TwoFactorCodeView({
  form,
  phoneLastDigits,
  loading,
  onResend,
  onSubmit,
  onBack,
  onBackupCodesSelected,
  errorMessage,
}: Props) {
  const [counter, { startCountdown, resetCountdown }] = useCountdown({ countStart: 30 });

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  return (
    <AuthWrapper
      textCenter
      onBack={onBack}
      title="Two-Factor authentication"
      linkTitle="Contact support"
      linkHref="mailto:cs@layerise.com"
      description={`To continue, please enter the ${SMS_CODE_LENGTH}-digit verification code sent to your phone ending in ...${phoneLastDigits}.`}>
      <Form method="post" onSubmit={e => e.preventDefault()}>
        <fieldset disabled={loading} aria-busy={loading}>
          <FormContainer>
            <CodeInputContainer>
              <AuthCodeInput
                onChange={code => {
                  form.setInputs({ code });
                  if (code.length === SMS_CODE_LENGTH) {
                    onSubmit(code);
                  }
                }}
                value={form.inputs.code}
                activating={loading}
                length={SMS_CODE_LENGTH}
              />
              <ErrorMessage>{errorMessage ? errorMessage : '‎'}</ErrorMessage>
            </CodeInputContainer>
          </FormContainer>
          <ResendAction>
            <BackupCodesActionContainer>
              Choose another method?{' '}
              <Link
                href="#"
                onClick={e => {
                  e.preventDefault();
                  onBackupCodesSelected();
                }}>
                Use backup code
              </Link>
            </BackupCodesActionContainer>
            <div>
              {counter > 0 ? (
                <>Resend available in {counter}</>
              ) : (
                <>
                  <Link
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      onResend?.();
                      resetCountdown();
                      startCountdown();
                    }}>
                    Resend
                  </Link>
                </>
              )}
            </div>
          </ResendAction>
        </fieldset>
      </Form>
    </AuthWrapper>
  );
}
